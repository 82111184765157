import React from 'react';
import './join-the-movement.scss';
import QuoteImage from "../../images/icons/quote.png"
import data from '../../../Content/join-the-movement.json';

const img = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/join-the-movement-mo-1.png';

const JoinTheMovement1 = (props) => {
    const { slider, user } = props;
    return (
        <div className="bg-1">
            <div className="bg-right-1">
                <div className="pw-jm bg-left">
                    <div className="w3-hide-small pw-jm-header">{data.title}</div>
                    <div><img loading="lazy" className="q-img w3-hide-small" src={QuoteImage} /></div>
                    <div className="pw-jm-sub-header">{data.header1}</div>
                    <div className="pw-jm-content">{data.content1}</div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button className="w3-round-xxlarge w3-button pw-jm-btn" onClick={() => slider.current.slickGoTo(1)}>
                            <div className="pw-jm-btn-text">
                                Enter the ParentWise Universe <span className="w3-hide-medium w3-hide-large pw-jm-btn-arrow" />
                            </div>
                        </button>
                    </div>
                    {!user ?
                        <div className="w3-center pw-jtm-skip" onClick={() => slider.current.slickGoTo(5)}>skip</div>
                    :null}
                    <div className="w3-hide-medium w3-hide-large"><img loading="lazy" className="img-1" src={img} /></div>
                </div>
            </div>
        </div>
    )
}

export default JoinTheMovement1;