import React from 'react';
import './join-the-movement.scss';
import data from '../../../Content/join-the-movement.json';

const img = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/join-the-movement-mo-2.png';
const cloudLeft = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/jm-cloud-left-mo-cr.png';
const cloudRight = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/jm-cloud-right-mo-cr.png';

const JoinTheMovement2 = (props) => {

    const { user } = props;
    
    return (
        <div className="bg-2">
            <div className="bg-right">
                <div className="pw-jm bg-left-2">
                    <div className="pw-jm-content-2">
                        {data.content2}
                    </div>
                    <div className="img-main w3-hide-medium w3-hide-large">
                        <img loading="lazy" className={`img-2 ${!user ? 'reg' : ''}`} src={img} />
                        <img loading="lazy" className="img-cl img-2-cl-left" src={cloudLeft} />
                        <img loading="lazy" className="img-cl img-2-cl-right" src={cloudRight} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinTheMovement2;