import React, { useState, useEffect, useRef, useContext } from 'react';
import Layout from '../components/layout';
import { Link } from "gatsby"
import "./pages.scss"
import '../components/JoinTheMovement/join-the-movement.scss';
import UpArrow from "../images/icons/up-arrow.png";
import Slide1 from '../components/JoinTheMovement/Slide1';
import Slide2 from '../components/JoinTheMovement/Slide2';
import Slide3 from '../components/JoinTheMovement/Slide3';
import Slide4 from '../components/JoinTheMovement/Slide4';
import Slide5 from '../components/JoinTheMovement/Slide5';
import Slide6 from '../components/JoinTheMovement/Slide6';
import SEO from "../components/seo";
import Slider from "react-slick";
import UserContext from '../context/UserContext';

const SmQuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/sm-quotes-group.png';
const QuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png';

const JoinTheMovementPage = () => {
    const userData = useContext(UserContext);
    const user = userData && userData.user;
    const [isFetching, setIsFetching] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const slider = useRef();
    
    const  afterSlideChange = (slide) => {
        setCurrentSlide(slide);
    }

    const settings = {
        className: "join-the-movement-slider",
        dots: true,
        arrows: true,
        swipeToSlide: true,
        draggable: true,
        swipe: true,
        slidesToShow: 1,
        infinite: false
    };

    useEffect(() => {
        if (currentSlide == 0) {
            document.documentElement.className = "arrow-disable-left";
        } else if (!user && currentSlide == 5) {
            document.documentElement.className = "arrow-disable-right";
        }  else if (user && currentSlide == 4) {
            document.documentElement.className = "arrow-disable-right";
        } else {
            document.documentElement.classList.remove("arrow-disable-left");
            document.documentElement.classList.remove("arrow-disable-right");
        }
    }, [currentSlide]);

    useEffect(() => {
        return () => {
            document.documentElement.classList.remove("pw-no-scroll");
        }
    }, []);

    return (
        <div className="pw-join-the-movement-page">
            <div className={`w3-display-container ${currentSlide == 5 ? 'pw-jm-rg-texture' : 'pw-jm-top-texture'}`}>
                <img loading="lazy" src={QuotesGroupIcon} className="pw-jm-top-right-texture w3-hide-small w3-hide-medium" />
                <img loading="lazy" src={SmQuotesGroupIcon} className="pw-jm-top-right-sm-texture w3-hide-medium w3-hide-large" />
                <Layout>
                    <SEO title="Join The Movement" />
                    <div className="w3-hide-large w3-hide-medium" style={{ display: "flex", position: "relative" }}>
                        <div className="pw-jm-title">JOIN THE MOVEMENT</div>
                    </div>
                    <div className="w3-hide-large w3-hide-medium join-the-movement-bor">
                        <Link to="/about-us" className="about-us-button">Why Parentwise</Link>                                                                                                                                                                                                                                                                                                                                                  
                        <span><img style={{ marginBottom: "5px" }} src={UpArrow} /></span>
                        <Link to="/join-the-movement" className="about-us-button about-us-border">Join the Movement</Link>
                        <Link to="/our-story" style={{ marginLeft: "15px" }} className="about-us-button">Our Story</Link>
                    </div>
                    <Slider {...settings} afterChange={(e) => afterSlideChange(e)} ref={slider}> 
                        <Slide1 slider={slider} user={user} />
                        <Slide2 user={user} />
                        <Slide3 user={user} />
                        <Slide4 user={user} />
                        <Slide5 user={user} />
                        {!user ?
                            <Slide6 user={user} />
                        : null}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                    </Slider>
                </Layout>
            </div>
        </div>
    )
}

export default JoinTheMovementPage;