import React, { useState, useEffect } from 'react';
import './join-the-movement.scss';
import SendOTP from '../../components/Auth/SendOTP';
import VerifyOTP from '../../components/Auth/VerifyOTP';
import '../Auth/auth.scss';
import { Link, navigate } from 'gatsby';
import tracker from '../../../utils/tracker';

const JoinTheMovement6 = (props) => {

    const { user } = props;

    const [formData, setFormData] = useState({});
    const [currentView, setCurrentView] = useState('SEND_OTP');
    const [currentData, setCurrentData] = useState('');
    const [type, setType] = useState('register');

    const handleOtpResponse = (data) => {
        if (data) {
            if (data.userExists) {
                setType('login');
            } else {
                setType('register');
            }
            setFormData({
                ...formData,
                ...data
            });
            setCurrentView('VERIFY_OTP');
        }
    };

    const handleGoBack = () => {
        if (currentView === 'VERIFY_OTP') {
            setCurrentView('SEND_OTP');
        } 
    }

    useEffect(() => {
        if (currentData && currentData.current_step) {
            if (currentData.current_step > 1) {
                navigate('/auth', { replace: true });
                return;
            } else if (currentData.current_step > 4) {
                navigate('/profile', { replace: true });
                return;
            }
        }
    }, [currentData]);

    const renderCurrentView = () => {

        switch(currentView) {
            case 'SEND_OTP':
                return (
                    <SendOTP type={type} handleResponse={handleOtpResponse} />
                );
            case 'VERIFY_OTP':
                return (
                    <VerifyOTP 
                        type={type}
                        data={formData}
                        setUser={setCurrentData}
                        handleOtpResponse={handleOtpResponse}
                        handleGoBack={handleGoBack}
                    />
                );
            default : return null;
        }
    }
    
    return (
        <div className="pw-jtm-reg-mgn">
            <div className="pw-register">
                {renderCurrentView()}
                <div className="w3-center desc">By registering you are accepting the {' '}
                    <Link to='/privacy-policy' style={{color: "#F1C555"}}>privacy policy</Link> of ParentWise
                </div>
            </div>
        </div>
    )
}

export default JoinTheMovement6;