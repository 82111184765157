import React from 'react';
import './join-the-movement.scss';
import data from '../../../Content/join-the-movement.json';

const img = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/join-the-movement-mo-5.png';
const cloudLeft = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/jm-cloud-left-mo-cr.png';
const cloudRight = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/jm-cloud-right-mo-cr.png';

const JoinTheMovement5 = (props) => {

    const { user } = props;
    
    return (
        <div className="bg-5">
            <div className="bg-right">
                <div className="pw-jm bg-left-5">
                    <div className="pw-jm-sub-header-4">{data.header5}</div>
                    <div className="pw-jm-content-5">{data.content5}</div>
                    <div className="img-main w3-hide-medium w3-hide-large">
                        <img loading="lazy" className={`img-5 ${!user ? 'reg' : ''}`} src={img} />
                        <img loading="lazy" className="img-cl img-5-cl-left" src={cloudLeft} />
                        <img loading="lazy" className="img-cl img-5-cl-right" src={cloudRight} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinTheMovement5;