import React from 'react';
import './join-the-movement.scss';
import data from '../../../Content/join-the-movement.json';

const img = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/join-the-movement-mo-3.png';

const JoinTheMovement3 = (props) => {

    const { user } = props;
    
    return (
        <div className="bg-3">
            <div className="bg-right">
                <div className="pw-jm bg-left-3">
                    <div className="pw-jm-sub-header-4">{data.header3}</div>
                    <div className="pw-jm-content-3">{data.content3}</div>
                    <div className="img-main w3-hide-medium w3-hide-large">
                        <img loading="lazy" className={`img-3 ${user ? 'reg' : ''}`} src={img} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinTheMovement3;